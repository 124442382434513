import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SideBar from '../Component/Sidebar';
import Header from '../Component/Header';
import { useTranslation } from 'react-i18next';

const Female = () => {

  const { t, i18n } = useTranslation();

  const [lng, setLng] = useState(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en');

  useEffect(() => {
    console.log('Changing language to1:', lng);
    // When the component mounts, set the language to the stored value (if available).
    i18n.changeLanguage(lng);
  }, [lng]);

  const changeLanguage = (newLng) => {
    console.log('Changing language to:', newLng);
    i18n.changeLanguage(newLng);
    setLng(newLng);
    // Store the selected language in localStorage.
    localStorage.setItem('selectedLanguage', newLng);
  };

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const visibleData = data.sort((a, b) => b.id - a.id).slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
    );

    console.log('ss',currentPage)
    
    const numbers = [...Array(totalPages + 1).keys()].slice(1);

  const MaleListURlAPI = 'https://api.sterility.gomaplus.tech/api/getFemale';

  async function get_female_list() {
    try {
      const response = await axios.get(MaleListURlAPI, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoader(false);
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  const jumpToStep = (index) => {
    const parsedStep = parseInt(index, 10);
    if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep < totalPages) {
      setCurrentPage(parsedStep);
    }
  };

  const filteredData = search
    ? data.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      })
    : visibleData;

  useEffect(() => {
    get_female_list();
  }, []);
  localStorage.setItem('page','/female')

  return (
    <div className="w-full h-screen md:flex justify-center items-center flex-col">
      <div>
        <SideBar page={localStorage.getItem('page')} changeLanguage={changeLanguage} lng={lng} />
      </div>
      <div className="w-[100%] sm:h-[22%] h-[35%]">
        <Header />
      </div>
      {loader ? (
        <div className="flex justify-center items-center w-[100%] h-[78%] gap-2">
          <div className="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
          <div className="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
          <div className="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
        </div>
      ) : (
        <div className="w-[100%] sm:h-[78%] h-[65%] flex justify-start items-center flex-col">
          <div className={`w-[96%] sm:h-[100%] h-[100%] md:-mt-0 sm:mt-20`}>
            <div className="h-[10%] md:w-[93%] w-[100%] mb-2 flex justify-between items-center">
              <input
                type="text"
                placeholder={t("search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="bg-transparent outline-none border-2 border-[#E4897B] w-[25%] p-2 rounded-lg text-right"
              />
              <p className="font-semibold text-lg text-[#afc1c5]">{t("femalePatients")}</p>
            </div>
            <table className="md:w-[93%] w-[100%] flex flex-col h-fit text-left border-separate border-spacing-y-4 ">
              <th className="bg-gray-100 shadow-xl flex justify-between items-center h-10 w-[100%] border rounded-lg border-blue-gray-50 text-sm text-[#363636CC] mb-1">
                <td className="flex md:justify-center justify-between items-center w-[50%] md:w-[12.5%] text-black text-center pl-6 md:pl-0">  {t("detail")}  </td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%]">{t("phone")}</td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%]">{t("email")}</td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%]">{t("country")}</td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%]">{t("birth")}</td>
                <td className="flex md:justify-center justify-end items-center w-[50%] md:w-[12.5%] pr-5 md:pr-0 text-black text-center">{t("name")} </td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%] ">{t("gender")}</td>
                <td className="hidden md:flex justify-center flex-col items-center text-black text-center w-[12.5%]">{t("serial")}</td>
              </th>
              {filteredData.length ? (
                filteredData.map((data, index) => {
                  let gender = data.gender === 'male' ? t("male") : t("female");
                  return (
                    <tr key={index} className="bg-white shadow-xl flex justify-between items-center h-24 w-[100%] border rounded-lg border-blue-gray-50 text-sm text-[#363636CC] mb-1">
                      <td className="flex md:justify-center justify-between items-center w-[50%] md:w-[12.5%]">
                        <Link to={`/details/${data.id}`}>
                          <button className="px-5 py-2 rounded-lg ml-2 text-white bg-[#E4897B]"> {t("detail")}</button>
                        </Link>
                      </td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] px-1 text-sm break-all">{data.phone}</td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] text-sm break-all">{data.email}</td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] text-sm break-all">{data.country}</td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] text-sm break-all">{data.date}</td>
                      <td className="flex md:justify-center justify-end items-center w-[50%] md:w-[12.5%] pr-3 md:pr-0 text-sm break-all">{data.name}</td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] text-sm break-all">{gender}</td>
                      <td className="hidden md:flex justify-center items-center w-[12.5%] text-sm break-all">{data.id}</td>
                    </tr>
                  );
                })
              ) : (
                <div className="md:w-[93%] w-[100%] h-32 text-red-500 text-xl flex justify-center items-center">{t("noData")}</div>
              )}
            </table>
            <div className="flex justify-between items-center w-fit lg:w-fit h-[30%] -mt-16 sm:gap-5">
              <div
                className={`text-center md:mr-[0.5%] sm:mr-[0.5%] lg:mr-[1%] sm:w-8 w-5 h-8 md:w-10 md:h-10 -mb-16 flex justify-center items-center md:text-base text-sm  py-2 rounded-full ${
                  0 === currentPage
                  ? "text-[#E4897B] hover:text-red-100"
                  : "text-[#666767] cursor-pointer hover:text-red-300"
                }`}
                onClick={() => jumpToStep(0)}
              >
                1
              </div>
              {currentPage  > 4 && (
                <div className="text-center md:mr-[0.5%] sm:mr-[0.5%] lg:mr-[1%] sm:w-8 w-3  h-8 md:w-10 md:h-10 -mb-16 flex justify-center items-center md:text-base text-sm  py-2 rounded-full">
                  ...
                </div>
              )}
              {numbers.map((step, index) => {
                if (index > 0 && index < totalPages - 1 && (index >= currentPage - 3 && index <= currentPage + 3)) {
                  return (
                    <div
                      key={index}
                      className={`text-center md:mr-[0.5%] sm:mr-[0.5%] lg:mr-[1%] sm:w-8 w-6 h-8 md:w-10 md:h-10 -mb-16 flex justify-center items-center md:text-base text-sm  py-2 rounded-full ${
                        index === currentPage
                        ? "text-[#E4897B]  hover:text-red-100"
                        : "text-[#666767] cursor-pointer hover:text-red-300"
                      }`}
                      onClick={() => jumpToStep(index)}
                    >
                      {index + 1}
                    </div>
                  );
                }
              })}
              {currentPage < totalPages - 5 && (
                <div className="text-center md:mr-[0.5%] sm:mr-[0.5%] lg:mr-[1%] sm:w-8 w-3 h-8 md:w-10 md:h-10 -mb-16 flex justify-center items-center md:text-base text-sm  py-2 rounded-full">
                  ...
                </div>
              )}
             {totalPages > 1 ? <div
                className={`text-center md:mr-[0.5%] sm:mr-[0.5%] lg:mr-[1%] sm:w-8 w-5 h-8 md:w-10 md:h-10 -mb-16 flex justify-center items-center md:text-base text-sm  py-2 rounded-full ${
                  totalPages - 1 === currentPage
                  ? "text-[#E4897B] hover:text-red-100"
                  : "text-[#666767] cursor-pointer hover:text-red-300"
                }`}
                onClick={() => jumpToStep(totalPages - 1)}
              >
                {totalPages}
              </div> : ''}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Female;



