import React from 'react'
import female from '../Images/Female.png'
import male from '../Images/Male.png'
import New from '../Images/New.png'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const GenderGrid = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

  return (
   <div>
    <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full'>
        <div className='bg-white rounded-lg p-4 flex-1 flex-col border-gray-200 flex shadow-md'>
          <div className='flex justify-between'>
            <div>
                <img className='w-28' src={New} />
            </div>
            <div className='flex flex-col justify-between'>
                <h2>{t("newPatients")}</h2>
                <Link to={`/new`}>
                <button className='flex flex-row gap-2 bg-[#44AEC3] p-2 rounded-lg text-sm text-white'>
                    {t("more")}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
</svg>
                </button>
                </Link>
            </div>
          </div>
        </div>



        <div className='bg-white rounded-lg p-4 flex-1 flex-col border-gray-200 flex shadow-md'>
          <div className='flex justify-between'>
            <div>
                <img className='w-28' src={male} />
            </div>
            <div className='flex flex-col justify-between'>
                <h2>{t("malePatients")}</h2>
                <Link to={`/male`}>
                <button  className='flex flex-row gap-2 bg-[#44AEC3] p-2 rounded-lg text-sm text-white'>
                    {t("more")}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
</svg>
                </button>
                </Link>
            </div>
          </div>
        </div>


       <div className='bg-white rounded-lg p-4 flex-1 flex-col border-gray-200 flex shadow-md'>
          <div className='flex justify-between'>
            <div>
                <img className='w-28' src={female} />
            </div>
            <div className='flex flex-col justify-between'>
                <h2>{t("femalePatients")}</h2>
                <Link to={`/female`}>
                <button className='flex flex-row gap-2 bg-[#44AEC3] p-2 rounded-lg text-sm text-white ml-5'>
                    {t("more")}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
</svg>
                </button>
                </Link>
            </div>
          </div>
        </div>
    </div>
   </div>
  )
}

export default GenderGrid
