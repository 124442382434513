import React,{ useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";
import Header from '../Component/Header';
import SideBar from '../Component/Sidebar';
import { useTranslation } from 'react-i18next';

const Details = () => {

  const { t, i18n } = useTranslation();

  const [lng, setLng] = useState(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en');

  useEffect(() => {
    console.log('Changing language to1:', lng);
    // When the component mounts, set the language to the stored value (if available).
    i18n.changeLanguage(lng);
  }, [lng]);

  const changeLanguage = (newLng) => {
    console.log('Changing language to:', newLng);
    i18n.changeLanguage(newLng);
    setLng(newLng);
    // Store the selected language in localStorage.
    localStorage.setItem('selectedLanguage', newLng);
  };
  
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState(1);
  const [loader,setLoader] = useState(true)
  const [data,setData] = useState([])
  let gender = data.gender == 'male' ? t("male") : t("female")

  const DetailsListURlAPI=`https://api.sterility.gomaplus.tech/api/users/${id}`
  async function get_details(){
   try{
     const response =await axios.get(DetailsListURlAPI,{
       headers:{
         "Authorization":`Bearer ${localStorage.getItem('token')}`,
         "Access-Control-Allow-Origin": "*",
       "Content-Type": "multipart/form-data",

       }
     })
     console.log('response',response.data)
     setLoader(false)
    setData(response.data)
   }
   catch(err){
     console.log(err)
   }
  }

  useEffect( ()=>{
    console.log(localStorage.getItem('token'))
    get_details()
   },[])
  
    const options = [
      { id: 3, label: t("test") },
      { id: 2, label: t("medical")},
      { id: 1, label: t("personal")},
    ];

     const handleOptionClick = (optionId) => {
      setSelectedOption(optionId);
    };
console.log('lng22', lng)

  return (

    <div className='w-full h-screen md:flex justify-center'>
      <div className='w-[100%] h-[90%]  overflow-hidden'>
      <div className=''>
        <SideBar page={localStorage.getItem('page')}  changeLanguage={changeLanguage} lng={lng}/>
        </div>
      <div className='w-[100%] sm:h-[22%] h-fit'>
        <Header/>
      </div>
     {loader ?<>
      <div class="flex justify-center items-center w-[100%] h-[78%] gap-2">
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#87b6e2]"></div>
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#87b6e2]"></div>
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#87b6e2]"></div>
</div> 
</>
 : <div className=' w-[93%] md:h-[78%] flex justify-center items-center md:mt-10 sm:mt-20 md:ml-0 sm:ml-7 ml-3'>

      <div className='w-[100%] md:h-[100%] h-[400px] flex justify-center items-center flex-col '>
        <div className='flex flex-row justify-between items-center md:w-[60%] w-[90%] h-[20%]'>
    {options.map((option) => (
        <div
          key={option.id}
          className={`cursor-pointer p-2 md:text-base text-sm md:w-40 w-32 text-center h-fit ${
            selectedOption === option.id
              ? ' border-[#44AEC3] border-b-2 -mb-[1px] z-10 '
              : ''
          }  `}
          onClick={() => handleOptionClick(option.id)}
        >
          {option.label}
         
        </div>
      ))}
       </div>

     {data ?  <div className='md:h-[70%] h-[80%] w-[90%]'>
              { selectedOption === 3 ?  <div className='flex justify-center items-center mt-10 w-[100%] h-[70%]'>
    {data.file ? <div className='md:w-[100%] w-[80%] flex justify-center items-center h-full bg-gray-50 p-5 rounded-xl shadow-lg shadow-gray-400'>
{data.file ? <a href={`https://api.sterility.gomaplus.tech${data.file}`}  target="_blank" className='px-1 sm:px-0 sm:w-[35%] lg:w-[20%] h-14 flex justify-center items-center rounded-xl bg-gray-500 text-white text-sm sm:text-base hover:text-gray-300'>{t("previewFile")}</a> : ''}   
 </div> : <p className="sm:text-xl w-[100%] h-72 flex justify-center items-center text-center text-[#44aec3]">{t("noData")}</p>}
    </div> :  selectedOption === 1 ?    <div className='flex justify-center items-center h-[95%] md:h-[50%] w-[90%] '>
        <div className='bg-gray-50 shadow-lg rounded-lg md:w-[80%] sm:w-[85%] w-[86%] px-4 md:pb-14 pb-5 md:top-96 top-[60%] left-7 sm:left-14 md:left-0 z-10 absolute h-[100%] md:h-[50%] md:ml-[8%]'>
            <div className='border-t border-l border-[#CC513D] h-full w-[40%] rounded-2xl -left-3 -top-3  absolute '></div>
            <div className='border-b border-r border-[#CC513D] h-[60%] w-[30%] rounded-2xl -right-3 -bottom-3  absolute '></div>
            <div className='flex justify-center items-center py-8 '>
            <h2 className='text-center'>{t("personal")}</h2>
            </div>
          <div className='w-[100%] h-[80%] md:grid grid-cols-3 grid-rows-2 items-center'>
            <div className='relative border-2 border-[#18A2D6] w-[95%] md:h-[50%] h-14 mb-10 md:mb-0 rounded-xl text-right p-4'>
            <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("gender")}</label>
            {gender}
            </div>

            <div className='relative border-2 border-[#18A2D6] w-[95%] md:h-[50%] h-14 mb-10 md:mb-0 rounded-xl text-right p-4'>
            <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("birth")}</label>
            {data.date}
            </div>

            <div className='relative border-2 border-[#18A2D6] w-[95%] md:h-[50%] h-14 mb-10 md:mb-0 rounded-xl text-right p-4'>
            <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("name")}</label>
            {data.name}
            </div>

            <div className='relative border-2 border-[#18A2D6] w-[95%] md:h-[50%] h-14 mb-10 md:mb-0 rounded-xl  p-4'>
            <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("phone")}</label>
            {data.phone}
            </div>

            <div className='relative border-2 border-[#18A2D6] w-[95%] md:h-[50%] h-14 mb-10 md:mb-0 rounded-xl p-4'>
            <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("email")}</label>
            {data.email}
            </div>

            <div className='w-[95%] md:h-[50%] h-14 flex justify-center items-center relative'>
                <ReactFlagsSelect
    selected={data.country}
    selectedSize={20}
    disabled
    className='w-[100%] bg-gray-200 border-2 border-[#18A2D6] flex rounded-lg '
  />
  <div className='sm:w-[98%] w-[98.5%] rounded-b-md h-3 md:top-[67%] top-[71%] bg-gray-200 absolute'></div>
  <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-7 lg:left-10 left-8' : 'md:right-7 lg:right-10 right-8'}  text-[#363636CC] bg-gray-50`}>{t("country")}</label>
                </div>
          </div>

            
        </div>
        </div> : selectedOption === 2 ? <> {data && data.question_user.length > 0 ? <div className='flex justify-center items-center h-[100%] md:h-[50%] w-[90%]'>
        <div className='bg-gray-50 shadow-lg rounded-lg md:w-[86.5%] lg:w-[80%] w-[86%] sm:w-[85%] px-4 md:pb-14 pb-5 lg:top-96 top-[60%] left-7 sm:left-14 md:-left-10 lg:left-0 z-10 absolute h-fit md:ml-[8%]'>
            <div className='border-t border-l border-[#CC513D] h-full w-[40%] rounded-2xl -left-3 -top-3  absolute '></div>
            <div className='border-b border-r border-[#CC513D] h-[60%] w-[30%] rounded-2xl -right-3 -bottom-3  absolute '></div>
            <div className='flex justify-center items-center py-8 '>
            <h2 className='text-center'>{t("medical")}</h2>
            </div>
            <div className='grid md:grid-cols-2 items-center w-[97%] h-[80%] gap-3 mt-5 md:-mt-5'>
        {data && data.question_user.length > 0 ? data.question_user.map((option) => (  
          <div className='w-[100%] h-[100%]'>
            <div className='relative border-2 border-[#18A2D6] w-[95%]  h-14 mb-5 md:mb-3 rounded-xl text-right p-4'>
           {lng == 'en' ? <label className=' text-[#363636CC] sm:px-1 lg:px-0 absolute md:-top-3 sm:-top-4 -top-3 left-2 sm:left-5 md:left-3 lg:left-[2%] text-[10px] sm:text-base md:text-sm lg:text-sm bg-gray-50'>{option.question.name}</label> : <label className=' text-[#363636CC] sm:px-1 lg:px-0 absolute md:-top-3 sm:-top-4 -top-3 right-2 sm:right-5 md:right-3 lg:right-[2%] text-[10px] sm:text-base md:text-sm lg:text-sm bg-gray-50'>{option.question.name_ar}</label>}
            {option.answer}
            </div>
          </div>)) : ''}
          </div>
        </div>
        </div> : <p className="sm:text-xl w-[100%] h-72 flex justify-center items-center text-center text-[#44aec3]">{t("noData")}</p>} </> : ''}
       </div> : ''} 
       </div>
      </div>}
      </div>
  </div>
  )
}

export default Details