import React, { useState, useEffect } from 'react'
import baby from '../Images/Dash2.png'
import doctors from '../Images/SignUp2.png'
import LogInInputs from '../MiniComponents/LogInInputs'
import { useTranslation } from 'react-i18next';

const LogIn = () => {

  const { t, i18n } = useTranslation();
  const changeLanguage = (newLng) => {
    console.log('Changing language to:', newLng);
    i18n.changeLanguage(newLng);
    setLng(newLng);
    localStorage.setItem('selectedLanguage', newLng);
  };
  const [lng, setLng] = useState(localStorage.getItem('selectedLanguage') || 'en');
    useEffect(() => {
      console.log('Changing language to1:', lng);
      i18n.changeLanguage(lng);
    }, [lng]);
  
  return (
<div className='w-full h-screen flex justify-center items-center'>
        <div className='w-[95%] h-[95%]'>
        <div className='bg-gradient-to-r from-[#3bb1dc5e] to-[#3bb1dc] w-[100%] h-72 rounded-3xl flex justify-between'>
            <div className='md:w-[25%] w-[40%] justify-start md:justify-center items-center flex '>
            <img src={baby} className='w-56 sm:h-56 h-48'/>
            </div>
            <div className='md:w-[50%] w-[60%] flex justify-center items-center'>
                <div className='w-[100%] text-white'>
            <h1 className='md:text-center text-center font-bold md:text-2xl sm:text-lg'>{t("Greeting")}</h1>
            </div>
            </div>
            <div className='w-[25%] justify-end items-end md:flex hidden'>
            <img src={doctors} className='w-56 h-56 rounded-b-3xl'/>
            </div>
            <select className='absolute right-[5%] top-7 bg-transparent text-white border-2 border-white rounded-lg lg:w-[7%] sm:w-[11%] w-[20%] h-[5%] outline-none' value={lng} onChange={(e)=>{changeLanguage(e.target.value)}}>
            <option value='en' className='text-white bg-[#3bb1dc]'>English</option>
            <option value='ar' className='text-white bg-[#3bb1dc]'>العربية</option>
          </select>
        </div>
        <div className='w-[100%] h-[59%] flex justify-center items-center'>
            <LogInInputs lng={lng}/>
        </div>
        </div>
    </div>
  )
}

export default LogIn