import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

 const LogInInputs = ({lng}) => {

  const { t, i18n } = useTranslation();
     
     const [email,setEmail] = useState('')
     const [password,setPassword] = useState('')
     const [warning,setWarning] = useState('')
     const [warning1,setWarning1] = useState(false)
     const [loader,setLoader] = useState(false)
     
     const nav = useNavigate()

    const LoginURlAPI='https://api.sterility.gomaplus.tech/api/login'
   async function LogIn(){
    setWarning(false)
    setWarning1(false)
    setLoader(true)
        try{
          const response=await axios.post(LoginURlAPI,{
            email: email,
            password: password
          })
          localStorage.setItem('token',response.data.access_token)    
            console.log(response.access_token)
            if(response.data.user.type == 'admin'){
            nav('/dashboard') 
           }else{
            setWarning1(true)
            setLoader(false)
            localStorage.clear('token')
           }
        }catch(err){
          setLoader(false)
    //  console.log(err.response.status)
     if(err.response && err.response.status == 401){
        setWarning(t("wrongEmail/pass"))
      }else  if(err.message == 'Network Error'){
        setWarning(t("tryAgain"))
      }else if (err.response && err.response.status === 500) {
        setWarning(t("serverError"));
      }
        }
    }
    
    useEffect (()=>{
      localStorage.removeItem('token');
    },[])

    // console.log('access_token',localStorage.getItem('token'))

    console.log('password',password)
    console.log('email',email)


  return (
    <div className='md:w-[50%] sm:w-[70%] w-[90%] h-[100%] bg-gray-50 rounded-2xl -mt-20  shadow-lg shadow-gray-400 flex justify-center items-center'>
        <div className='w-[95%] h-[90%]'>
            <p className='text-center h-[15%]'>
            {t("loggingin")}
            </p>
            <div className='w-[100%] h-[50%] flex justify-center items-center'>
                <div className='w-[100%] h-[100%] '>
                    <div className='relative w-[100%] h-[40%]'>
                <input name='email' type='email' className='w-[90%] h-[70%] border-2 border-[#18A2D6] rounded-xl outline-none ml-4 p-3 pr-16' onChange={(e)=>setEmail(e.target.value)}/>
                <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-10 lg:left-14 left-12' : 'md:right-12 lg:right-16 right-12'}  text-[#363636CC] bg-gray-50`}>{t("email")}</label>
                {/* <label for='email' className='absolute px-1 top-6 lg:right-14 md:right-12 right-10 text-[#363636CC]'><BsEnvelopeFill  size={22} color='#18A2D6'/> </label> */}
                </div>
                <div className='relative w-[100%] h-[40%]'>
                <input name='password' type='password' className='w-[90%] h-[70%]  border-2 border-[#18A2D6] rounded-xl outline-none ml-4 p-3 pr-16' onChange={(e)=>setPassword(e.target.value)}/>
                <label for='email' className={`absolute px-1 -top-4 ${lng == 'en' ? 'md:left-10 lg:left-14 left-12' : 'md:right-12 lg:right-16 right-12'}  text-[#363636CC] bg-gray-50`}>{t("password")}</label>
                {/* <label for='password' className='absolute px-1 top-6 lg:right-14 md:right-12 sm:right-10 right-8 text-[#363636CC]'><BsFillKeyFill  size={22} color='#18A2D6'/> </label> */}
             </div>
                </div>
            </div>
            <div className='w-[100%] h-[35%] flex flex-col justify-center items-center'>
                {warning ? <div className=' w-[95%] sm:w-[80%] lg:w-[70%] h-[50%] rounded-lg bg-red-200 border-2 border-red-500 text-center text-red-500 flex justify-center items-center mb-[3%]'> {warning}</div> : ''}
                {warning1 ? <div className=' w-[95%] sm:w-[80%] lg:w-[70%] h-[50%] rounded-lg bg-red-200 border-2 border-red-500 text-center text-red-500 flex justify-center items-center mb-[3%]'>{t("permission")} </div> : ''}
                <button className='bg-gradient-to-b from-[#E45E47] to-[#EB996E] md:w-[40%] w-[60%] lg:w-[70%] h-[40%] text-center text-white rounded-xl' onClick={LogIn}>{loader ?  <div className='w-[100%] h-[100%] flex justify-center items-center'> 
                <div class=" h-6 w-6 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span>
</div>
</div>
 :
  t("login")}</button>
            </div>
        </div>
    </div>
  )
}

export default LogInInputs