import React from 'react'
import ChartsGrid from '../MiniComponents/ChartsGrid'
import GenderGrid from '../MiniComponents/GenderGrid'
import Calendar from '../MiniComponents/Calendar'

const Dashboard = () => {
  return (
    <div className='flex flex-col gap-5 w-[100%] md:w-[97%] h-[100vh] mt-[15%] mb-5 md:mb-0' >
        <div>
      <ChartsGrid/>
        </div>
        <div>
      <GenderGrid/>
        </div>
        <div className='md:block hidden'>
          <Calendar/>
        </div>
    </div>
  )
}

export default Dashboard

