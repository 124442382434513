import React, { useState,useEffect } from 'react'
import SideBar from '../Component/Sidebar'
import Header from '../Component/Header'
import Dash from '../PageComponent/Dash'
import { useTranslation } from 'react-i18next';


 
const Dashboard = () => {

  const { t, i18n } = useTranslation();

  const [lng, setLng] = useState(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en');

  useEffect(() => {
    console.log('Changing language to1:', lng);
    // When the component mounts, set the language to the stored value (if available).
    i18n.changeLanguage(lng);
  }, [lng]);

  const changeLanguage = (newLng) => {
    console.log('Changing language to:', newLng);
    i18n.changeLanguage(newLng);
    setLng(newLng);
    // Store the selected language in localStorage.
    localStorage.setItem('selectedLanguage', newLng);
  };

const [loader,setLoader] = useState(false)

console.log('access_token',localStorage.getItem('token'))

  return (
    <div className='w-full h-screen md:flex items-center flex-col justify-center'>
         <div className=''>
      <SideBar page={'/dashboard'} changeLanguage={changeLanguage} lng={lng}/>
      </div>
      <div className='w-[100%] sm:h-[22%] h-fit'>
        <Header/>
      </div>
      {loader ? <>
      <div class="flex justify-center items-center w-[100%] h-[78%] gap-2">
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
    <div class="w-9 h-9 rounded-full animate-pulse bg-[#44AEC3]"></div>
</div> 
      </> : <>
        <div className='w-[100%] h-[78%]'>
        <div className=' w-[93%] md:h-[78%] flex justify-center items-center md:mt-24 sm:mt-20 md:ml-0 sm:ml-7 ml-3'>
   <Dash/>
        </div>
        </div> </> }
    </div>
    
  )
}

export default Dashboard