import React from 'react'
import dash1 from '../Images/Dash1.png'
import dash2 from '../Images/Dash2.png'
import dash3 from '../Images/Dash3.png'
import { useTranslation } from 'react-i18next';


const Header = () => {
  let lng = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en';
  const { t, i18n } = useTranslation();
  console.log('lng22', lng)
  return (
    <div className='md:w-[93.5%] lg:w-[95.2%] w-[100%] bg-[#44AEC3] md:h-36 h-60 rounded-b-lg text-white p-4 '>
        <div className={` ${lng == 'en' ? ' flex sm:flex-row-reverse flex-col' : 'flex sm:flex-row flex-col' } justify-between w-[100%] h-[100%]`}>
          <div className='hidden sm:flex sm:justify-evenly justify-center items-center sm:gap-[10%] sm:w-[60%] w-[1005] sm:h-[100%] h-[50%] -ml-6 sm:ml-0'>
            <img className='h-[120%] md:w-[30%] sm:w-24 w-[25%]' loading="lazy" src={dash2} />
            <img className='h-[110%] md:w-[30%] sm:w-32 w-[40%] mt-5 -ml-8 sm:ml-0' loading="lazy" src={dash3} />
            <img className='h-[80%] md:w-[20%] w-[25%]  sm:mt-12 block sm:hidden md:block' loading="lazy" src={dash1} />
            </div>
        <div className={`flex flex-col justify-center ${lng == 'en' ? ' text-left' : 'text-right' } md:text-lg text-sm gap-3 sm:w-[40%] w-[100%] sm:h-[100%] h-[50%]`}>
            <h2 className='font-bold md:text-xl text-lg'>{t("welcome")}</h2>
            <h2>{t("welcome2")} </h2>
            <h2>{t("welcome3")}</h2>
        </div>
      </div>
    </div>
  )
}

export default Header
