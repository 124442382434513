import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LogIn from './Pages/LogIn';
import Dashboard from './Pages/Dashboard';
import New from './Pages/New';
import Male from './Pages/Male';
import Female from './Pages/Female';
import Details from './Pages/Details';

function App() {



  return (
    <div className="App">
      <Router>
    <Routes>
    <Route path='/' Component={LogIn}></Route>
    <Route path='/dashboard' element={<Dashboard />}></Route>
    <Route path='/new' element={<New/>}></Route>
    <Route path='/male' element={<Male />}></Route>
    <Route path='/female' element={<Female />}></Route>
    <Route path="/details/:id" element={<Details/>} />

    </Routes>
    </Router>
    </div>
  );
}

export default App;
