import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next';

const ChartsGrid = () => {
  const { t, i18n } = useTranslation(); 

  console.log('access_token',localStorage.getItem('token'))

  const [nation,setNation] = useState([])
  const NationURlAPI='https://api.sterility.gomaplus.tech/api/getNationalityPercentage'
  async function nationRate(){
       try{
         const response=await axios.get(NationURlAPI,{
          headers:{
            "Authorization":`Bearer ${localStorage.getItem('token')}`,
            "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
   
          }
        })    
           console.log('response',response.data.Nationality)
           setNation(response.data.Nationality)
       }catch(err){
    console.log(err)
       }
   }

   const [series, setSeries] = useState([]);
   const [label, setLabel] = useState([])
   const [maxNation, setMaxNation] = useState(0)
   const [maxNationName, setMaxNationName] = useState('')

   

   console.log('labels',label)

   useEffect(() => {
     countNation();
   }, [nation]);

   function countNation() {
    if (nation && nation.length > 0) {
      const maxNationalities = 4;
      let nationalities = [...nation];
      let seriesData = [];
      let labels = [];
  
      if (nation.length > maxNationalities) {
        nationalities = nation.slice(0, maxNationalities);
  
        const othersPercentage = nation
          .slice(maxNationalities)
          .reduce((acc, nation) => acc + nation.total, 0);
  
        seriesData = nationalities.map((nation) =>Number(nation.total));
        labels = nationalities.map((nation) => nation.country);
  
        seriesData.push(othersPercentage);
      } else {
        seriesData = nationalities.map((nation) => Number(nation.total));
        labels = nationalities.map((nation) => nation.country);
      }
  
      setSeries(seriesData);
      setLabel(labels)

      const highestRate = Math.max(...seriesData);
      setMaxNation(highestRate)

      
      // Find the nationality with the highest rate
      const highestNationIndex = seriesData.indexOf(highestRate);
      const highestNation = labels[highestNationIndex];
      setMaxNationName(highestNation)
     
    }
  }
  console.log('highestRate' , maxNationName)

   const [male , setMale] = useState(0)
   let intMale = Math.round(male)
   let totalMale = 100 - intMale
   const MaleURlAPI='https://api.sterility.gomaplus.tech/api/getRate'
   async function maleRate(){
        try{
          const response=await axios.post(MaleURlAPI,{
           status: 'male'
          },{
            headers: {
              "Authorization": `Bearer ${localStorage.getItem('token')}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "multipart/form-data"
            }
          })    
            console.log(response.data)
            setMale(response.data)
        }catch(err){
     console.log(err)
        }
    }
 


  const [female , setFemale] = useState(0)
  let intFemale = Math.round(female)
  let totalFemale = 100 - intFemale
  const FemaleURlAPI='https://api.sterility.gomaplus.tech/api/getRate'
  async function femaleRate(){
       try{
         const response=await axios.post(FemaleURlAPI,{
          status: 'female'
         },{
           headers:{
             "Authorization":`Bearer ${localStorage.getItem('token')}`,
             "Access-Control-Allow-Origin": "*",
             "Content-Type": "multipart/form-data",
             
            }})    
             console.log(response.data)
           setFemale(response.data)
       }catch(err){
    console.log(err)
       }
   }

   useEffect (()=>{
   femaleRate()
   maleRate()
   nationRate()
  },[])

  return (
    <div>
    <div className='grid md:grid-cols-3 grid-cols-1 gap-4 w-full'>
        <div className='bg-white rounded-lg p-4 flex-1 flex-col justify-center items-center border-gray-200 flex shadow-md'>
           <div id="chart" className='flex flex-col justify-center items-center relative'>
           <Chart
      type="donut"
      series={series && series.length > 0 ? series : [100]}
      options={{
        labels:label,
        legend: {
          show: false
        },
        colors:['#EEC1BB' , '#44AEC3' , '#5B98E8' , '#D9D9D9'],
       fill: {
  colors: ['#EEC1BB' , '#44AEC3' , '#5B98E8' , '#D9D9D9']
},

        plotOptions:{
            pie:{
                donut:{
                    labels:{
                        show:false,
                        total:{
                            show:false,
                            
                            showAlways:true,
                            fontSize:16
                        }
                        
                    }
                }
            }
        },
        dataLabels:{
            enabled:false
        }
        
      }}
      />
            <h2 className='text-center md:text-base text-sm'>{t("nation")}</h2>
            <p className='absolute text-2xl sm:text-2xl md:text-lg lg:text-2xl font-semibold lg:top-[30%] top-[32%] md:top-[28.5%]'>{maxNationName}</p>
            <p className='absolute text-2xl sm:text-2xl md:text-lg lg:text-2xl font-thin lg:top-[45%] top-[47%] md:top-[43.5%]'>{maxNation}%</p>
         </div>
        </div>
        <div className='bg-white rounded-lg p-4 flex-1 border-gray-200 flex justify-center items-center shadow-md'>
           <div id="chart" className='flex flex-col justify-center items-center relative'>
           <Chart
      type="donut"
      series={[intFemale,totalFemale]}
      options={{
        labels:[t("female"),t("rest")],
        legend: {
          show: false, // Set this to false to hide the legend
        },
        colors:['#EEC1BB', '#DFDFDF'],
       fill: {
  colors: ['#EEC1BB', '#DFDFDF']
},

        plotOptions:{
            pie:{
                donut:{
                    labels:{
                        show:false,
                        total:{
                            show:false,
                            
                            showAlways:true,
                            fontSize:16
                        }
                        
                    }
                }
            }
        },
        dataLabels:{
            enabled:false
        }
        
      }}
      />
      <h2 className='text-center md:text-base text-sm'>{t("femalePercent")}</h2>
      <p className='absolute text-2xl sm:text-4xl md:text-2xl lg:text-4xl font-semibold lg:top-[35%] top-[37%] md:top-[33.5%]'>{intFemale}%</p>
         </div>
        </div>
        <div className='bg-white rounded-lg p-4 flex-1 border-gray-200 flex justify-center items-center shadow-md'>
           <div id="chart" className='flex flex-col justify-center items-center relative'>
      <Chart
      type="donut"
      series={[intMale,totalMale]}
      options={{
        labels:[t("male"),t("rest")],
        legend: {
          show: false, // Set this to false to hide the legend
        },
        colors:['#44AEC3', '#DFDFDF'],
       fill: {
  colors: ['#44AEC3', '#DFDFDF']
},

        plotOptions:{
            pie:{
                donut:{
                    labels:{
                        show:false,
                        total:{
                            show:false,
                            
                            showAlways:true,
                            fontSize:16
                        }
                        
                    }
                }
            }
        },
        dataLabels:{
            enabled:false
        }
        
      }}
      />
      <h2 className='text-center md:text-base text-sm'>{t("malePercent")}</h2>
      <p className='absolute text-2xl sm:text-4xl md:text-2xl lg:text-4xl font-semibold lg:top-[35%] top-[37%] md:top-[33.5%]'>{intMale}%</p>
         </div>
        </div>
    </div>
   </div>
  )
}

export default ChartsGrid
